import React from 'react';
import './styles.css';
import Lobby from './Lobby';  // Uvozimo novo Lobby komponento

function App() {
  return (
    <div className="App">
      <div class="container">
        <h1>Battleship Game</h1>
      </div>
      <Lobby />
    </div>
  );
}

export default App;
