import React, { useState, useEffect, useRef } from 'react';
import './styles.css';
import connection from './signalrService';

const GameBoard = ({ roomName, playerName, onGameEnd }) => {
    const [board, setBoard] = useState(() => Array.from({ length: 10 }, () => Array(10).fill(null)));
    const [isPlayerTurn, setIsPlayerTurn] = useState(false);
    const [placementPhase, setPlacementPhase] = useState(true);
    const [timeLeft, setTimeLeft] = useState(15); // Za oba igralca
    const [gameOver, setGameOver] = useState(false);
    const [ships, setShips] = useState(5); // Vsak igralec ima 5 ladij
    const timerRef = useRef(null); // Timer reference za odštevanje

    const updateBoard = (x, y, value) => {
        setBoard(prevBoard => {
            const newBoard = prevBoard.map(row => [...row]);
            newBoard[x][y] = value;
            return newBoard;
        });
    };

    useEffect(() => {
        const startConnection = async () => {
            if (connection.state === 'Disconnected') {
                await connection.start();
            }
            if (connection.state === 'Connected') {
                await connection.invoke('JoinRoom', roomName, playerName);
            }
        };

        startConnection().catch(err => console.error('Error connecting or joining room:', err));

        // SignalR dogodki
        connection.on('ShipPlaced', (player, x, y) => {
            if (player === playerName) {
                updateBoard(x, y, 'S');
            }
        });

        connection.on('GuessResult', (player, x, y, result) => {
            updateBoard(x, y, result === 'Hit' ? 'X' : 'O');
        });

        connection.on('YourTurn', (player) => {
            setIsPlayerTurn(player === playerName);
        });

        connection.on('StartPlacementPhase', (time) => {
            setPlacementPhase(true);
            setTimeLeft(time);

            // Odštevanje časa za oba igralca
            clearInterval(timerRef.current);
            timerRef.current = setInterval(() => {
                setTimeLeft(prev => {
                    if (prev <= 1) {
                        clearInterval(timerRef.current);
                        return 0;
                    }
                    return prev - 1;
                });
            }, 1000);
        });

        connection.on('EndPlacementPhase', () => {
            setPlacementPhase(false);
            clearInterval(timerRef.current);
        });

        connection.on('GameOver', async (winner) => {
            alert(`Konec igre! Zmagovalec: ${winner}`);
            setGameOver(true);
            await connection.invoke('RemoveRoom', roomName);
            onGameEnd(winner);
        });

        return () => {
            clearInterval(timerRef.current);
            connection.off('ShipPlaced');
            connection.off('GuessResult');
            connection.off('YourTurn');
            connection.off('StartPlacementPhase');
            connection.off('EndPlacementPhase');
            connection.off('GameOver');
        };
    }, [roomName, playerName, onGameEnd]);

    const handleCellClick = (x, y) => {
        if (gameOver || (placementPhase && ships <= 0)) return;

        if (placementPhase && ships > 0) {
            updateBoard(x, y, 'S'); // Postavi ladjo
            setShips(ships - 1); // Zmanjšaj število ladij po postavitvi
            connection.invoke('PlaceShip', roomName, playerName, x, y)
                .catch(err => console.error('Napaka pri postavitvi ladje:', err));
        } else if (isPlayerTurn) {
            connection.invoke('MakeGuess', roomName, playerName, x, y)
                .catch(err => console.error('Napaka pri ugibanju:', err));
        }
    };

    const getCellStyle = (cell) => {
        switch (cell) {
            case 'S':
                return { backgroundColor: '#4caf50' }; // Igralčeva ladja
            case 'X':
                return { backgroundColor: '#f44336' }; // Zadetek
            case 'O':
                return { backgroundColor: '#2196f3' }; // Zgrešeno
            default:
                return { backgroundColor: '#fff' }; // Prazna celica
        }
    };

    return (
        <div className="container">
            {placementPhase && <div className="status-bar">Čas za postavljanje ladij: {timeLeft}s</div>}
            {isPlayerTurn && !placementPhase && <div className="status-bar">Tvoj potez!</div>}
            <div className="game-board-container">
                <div className="game-board">
                    {board.map((row, rowIndex) => (
                        <React.Fragment key={rowIndex}>
                            {row.map((cell, colIndex) => (
                                <div
                                    key={colIndex}
                                    className="board-cell"
                                    onClick={() => handleCellClick(rowIndex, colIndex)}
                                    style={getCellStyle(cell)}
                                />
                            ))}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default GameBoard;
