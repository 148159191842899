import * as signalR from '@microsoft/signalr';

const connection = new signalR.HubConnectionBuilder()
    .withUrl('https://potapljaj.fun/BattleshipHub') // Zamenjaj z dejanskim URL-jem tvojega API-ja
    .withAutomaticReconnect()
    .build();

connection.start()
    .then(() => console.log('Connected to SignalR Hub'))
    .catch(err => console.log('Error connecting to SignalR Hub:', err));

export default connection;
