import React, { useState, useEffect } from 'react';
import GameBoard from './GameBoard';
import connection from './signalrService';
import * as signalR from '@microsoft/signalr';

const Lobby = () => {
    const [playerName, setPlayerName] = useState('');
    const [roomName, setRoomName] = useState('');
    const [rooms, setRooms] = useState([]);
    const [hasJoined, setHasJoined] = useState(false);
    const [winner, setWinner] = useState(null);

    const startConnection = async () => {
        try {
            if (connection.state === signalR.HubConnectionState.Disconnected) {
                await connection.start();
            }
            return connection.state === signalR.HubConnectionState.Connected;
        } catch (error) {
            console.error("Napaka pri vzpostavljanju povezave s SignalR:", error);
            return false;
        }
    };

    const fetchRooms = async () => {
        if (await startConnection()) {
            try {
                console.log("Pridobivam sobe...");
                await connection.invoke('GetAllRooms');
            } catch (error) {
                console.error("Napaka pri pridobivanju sob:", error);
            }
        }
    };

    useEffect(() => {
        fetchRooms();

        connection.on("ReceiveAllRooms", (fetchedRooms) => {
            console.log("Prejete sobe:", fetchedRooms);
            setRooms(fetchedRooms || []); // Preveri, če so sobe null ali undefined
        });

        connection.on("PlayerJoined", (player, roomPlayersCount) => {
            console.log(`${player} se je pridružil sobi. Število igralcev: ${roomPlayersCount}`);
            if (roomPlayersCount === 2) {
                setHasJoined(true); // Takoj nastavimo hasJoined, ko se pridruži drugi igralec
            }
        });

        connection.on("RoomUpdated", fetchRooms);

        return () => {
            connection.off("ReceiveAllRooms");
            connection.off("PlayerJoined");
            connection.off("RoomUpdated");
        };
    }, []);

    const handleJoin = async () => {
        if (playerName && roomName) {
            try {
                if (await startConnection()) {
                    console.log(`Pridružujem se sobi ${roomName} kot ${playerName}...`);
                    await connection.invoke('JoinRoom', roomName, playerName);
                    setHasJoined(true);
                } else {
                    console.error('Povezava s SignalR ni vzpostavljena');
                }
            } catch (error) {
                console.error('Napaka pri pridružitvi sobi:', error);
            }
        } else {
            alert('Prosim vnesi ime igralca in ime sobe');
        }
    };

    const handleGameEnd = (winner) => {
        setWinner(winner);
        setHasJoined(false);
        fetchRooms(); // Osveži sobe po koncu igre
    };

    if (hasJoined) {
        return <GameBoard roomName={roomName} playerName={playerName} onGameEnd={handleGameEnd} />;
    }

    return (
        <div className="container">
            <h2>Vnesi svoje ime in sobo, da se pridružiš igri</h2>
            <input
                type="text"
                placeholder="Vnesi ime igralca"
                value={playerName}
                onChange={(e) => setPlayerName(e.target.value)}
            />
            <input
                type="text"
                placeholder="Vnesi ime sobe"
                value={roomName}
                onChange={(e) => setRoomName(e.target.value)}
            />
            <button onClick={handleJoin}>Pridruži se igri</button>

            {winner && <div className="status-bar">Zmagovalec prejšnje igre: {winner}</div>}

            <h3>Razpoložljive sobe:</h3>
            <ul className="room-list">
                {rooms.length > 0 ? (
                    rooms.map(room => (
                        <li key={room}>
                            <span>{room}</span>
                            <button onClick={() => setRoomName(room)}>Pridruži se tej sobi</button>
                        </li>
                    ))
                ) : (
                    <li>Ni razpoložljivih sob</li>
                )}
            </ul>
        </div>
    );
};

export default Lobby;
